<template>
  <div class="message-chat d-flex flex-column justify-content-around h-100">
    <!-- HEADER -->
    <div class="task-header d-flex flex-row" v-if="!admin || showHeader" :class="[orderView ? 'justify-content-end order-view-message-header': 'pb-0 pb-md-3 mt-2 mt-md-3 px-2 px-md-3 w-100']">
      <div class="d-flex flex-column task-info col-12 col-md-8 p-0 pb-2 pb-md-0" v-if="!orderView">
        <div class="d-flex justify-content-start">
          <!-- mobile only back button -->
          <b-button v-if="displayBackButton" class="d-lg-none mr-2 back-btn" v-on:click="displayInbox" v-b-modal.modal-report-1 size="sm" v-b-popover.hover.top="$t('back')" >
            <i class="fa-solid fa-angle-left text-primary" aria-hidden="true" />
          </b-button>
          <!-- Header container -->
          <div class="d-flex flex-column justify-content-center align-items-start">
            <!-- Title -->
            <h3 class="p-0 text-left font-weight-bold mb-0">
              <router-link v-if="messages[0].order_hash" :to="{ name:'task_info', params:{ username: messages[0].seller_username, slug: messages[0].task_slug } }" class="text-dark">
                {{messages[0].task_title}}
              </router-link>
              <div v-else-if="messages[0].task_title !== 'Direct message'">
                {{messages[0].task_title}}
              </div>
              <router-link v-else :to="{ name:'profile', params:{ username: username === toUsername ? fromUsername : toUsername} }" class="text-dark">
                {{username === toUsername ? fromUsername : toUsername}}
              </router-link>
            </h3>
            <!-- DM online pill only -->
            <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds) && !messages[0].order_hash && jobDetails.hash === null" class="position-relative d-inline-flex align-self-start">
              <span class="user-online badge badge-success">{{ $t('online') }}</span>
            </div>
            <!-- task message header -->
            <div class="d-flex flex-row task-sub-info" v-if="messages[0].order_hash">
              <div class="mr-3 d-flex justify-content-start align-items-center" v-if="messages[0].seller_username !== username">
                <div class="position-relative d-flex justify-content-start align-items-center">
                  <span class="mr-1">{{ $t('seller') }}:&nbsp;</span>
                  <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds)" class="position-relative d-inline-flex mr-1">
                    <span class="user-online badge badge-success">{{ $t('online') }}</span>
                  </div>
                  <span class="font-weight-bold text-left username">
                    <router-link :to="{name:'profile', params:{username: messages[0].seller_username}}" class="text-dark">
                      {{ messages[0].seller_username }}
                    </router-link>
                  </span>
                </div>
              </div>
              <div class="mr-3" v-else>
                <div class="position-relative d-flex justify-content-start align-items-center">
                  <span class="mr-1">{{ $t('buyer') }}:</span>
                  <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds)" class="position-relative d-inline-flex mr-1">
                    <span class="user-online badge badge-success">{{ $t('online') }}</span>
                  </div>
                  <span class="font-weight-bold text-left username">
                    <router-link :to="{name:'profile', params:{username: messages[0].buyer_username}}" class="font-weight-bold text-dark">
                      {{ messages[0].buyer_username }}
                    </router-link>
                  </span>
                </div>
              </div>
              <div>
                {{ $t('order_reference_short') }}:
                <a class="font-weight-bold" @click="$router.push({ name: 'receive_task', params: {hash: messages[0].order_hash} })">
                  #{{messages[0].order_hash}}
                </a>
              </div>
            </div>
            <!-- Job message header -->
            <div class="d-flex flex-row task-sub-info" v-else-if="this.jobDetails.hash !== null">
              <div class="mr-3 d-flex justify-content-start align-items-center" v-if="fromUsername !== this.username">
                <div class="position-relative d-flex justify-content-start align-items-center">
                  <span class="mr-1">{{ $t('seller') }}:&nbsp;</span>
                  <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds)" class="position-relative d-inline-flex mr-1">
                    <span class="user-online badge badge-success">{{ $t('online') }}</span>
                  </div>
                  <span class="font-weight-bold text-left username">
                    <router-link :to="{name:'profile', params:{username: fromUsername}}" class="text-dark">
                      {{ fromUsername }}
                    </router-link>
                  </span>
                </div>
              </div>
              <div class="mr-3" v-else>
                <div class="position-relative d-flex justify-content-start align-items-center">
                  <span class="mr-1">{{ $t('buyer') }}:</span>
                  <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds)" class="position-relative d-inline-flex mr-1">
                    <span class="user-online badge badge-success">{{ $t('online') }}</span>
                  </div>
                  <span class="font-weight-bold text-left username">
                    {{ toUsername }}
                  </span>
                </div>
              </div>
            </div>
            <!-- last seen & local time -->
            <div v-if="isUserOnline(this.username === this.toUsername ? this.fromLastActiveDate : this.toLastActiveDate, this.withinLastSeconds) === false" class="d-flex flex-row task-seen-info justify-content-start">
              <!--last seen-->
              <span v-if="this.username === this.fromUsername ? this.toLastActiveDate !== null : this.fromLastActiveDate !== null">{{$t('last_seen')}}
                {{ displayLastSeen(this.username === this.fromUsername ? this.toLastActiveDate : this.fromLastActiveDate) }}</span>
              <!--local time-->
              <span v-if="this.username !== messages[0].author_username && messages[0].author_time_zone !== null" class="text-left">
                <!--divider-->
                <span v-if="this.username === messages[0].author_username ? this.toLastActiveDate !== null : this.fromLastActiveDate !== null" class="d-none d-sm-inline-block">&nbsp;|&nbsp;</span>
                {{$t('local_time')}}: {{localTime(messages[0].author_time_zone)}}
              </span>
              <span v-else-if="messages[1] && this.username !== messages[1].author_username && messages[1].author_time_zone !== null" class="text-left">
                <span v-if="this.username === messages[0].author_username ? this.toLastActiveDate !== null : this.fromLastActiveDate !== null" class="d-none d-sm-inline-block">&nbsp;|&nbsp;</span>
                {{$t('local_time')}}: {{localTime(messages[1].author_time_zone)}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row task-actions col-12 col-md-4 py-2 px-0 justify-content-between">
        <GoogleTranslate class="ml-0" ref="googleTranslate" v-on:translate="translateWord"/>
        <div class="h-100 d-flex justify-content-end flag-button">
          <b-button v-on:click="report()" v-b-modal.modal-report-1 size="sm" variant="light" class="border h-100" v-b-popover.hover.top="$t('report_type_message')" ><i class="fa-solid fa-flag" aria-hidden="true" /></b-button>
        </div>
      </div>
    </div>

    <!-- Custom task info -->
    <div v-if="customDetail.status != null" class="custom-task-details d-flex flex-column flex-sm-row justify-content-between align-items-center px-3 py-1">
      <div class="d-flex flex-row align-items-center py-2" style="cursor: pointer;"
           :class="visible ? null : 'collapsed'"
           :aria-expanded="visible ? 'true' : 'false'"
           aria-controls="custom-task-details-description"
           @click="visible = !visible">
        <i :class="visible ? 'fa-chevron-up' : 'fa-chevron-down'" class="fa-solid mr-1" aria-hidden="true"/><span>{{ visible ? $t('hide_custom_quote_details') : $t('show_custom_quote_details')  }}</span>
      </div>
      <!-- Custom task info SELLER-->
      <div v-if="customDetail.status === 0 && this.username !== messages[0].author_username" class="button-holders">
        <div v-if="lock !== 1 && !admin" class="d-flex flex-column flex-sm-row">
          <button class="btn btn-red px-4 py-1 mr-0 mr-sm-2 mb-2 mb-sm-0" type="button" v-on:click="acceptDeclineQuote(customDetail.hash, 0, username, messages[0].author_username)">{{ $t('decline_button') }}</button>
          <button class="btn btn-green px-4 py-1 mb-2 mb-sm-0" type="button" v-b-modal.amendModal>{{ $t('review_quote') }}</button>
        </div>
      </div>
      <!-- Custom task info BUYER-->
      <div v-if="customDetail.status != null && this.username === messages[0].author_username" class="button-holders">
        <div v-if="lock !== 1 && !admin" class="d-flex flex-column flex-sm-row">
          <button v-if="customDetail.status === 0 || customDetail.status === 1" class="btn btn-red px-4 py-1 mr-0 mr-sm-2 mb-2 mb-sm-0" type="button" v-on:click="acceptDeclineQuote(customDetail.hash, 0, username, messages[0].author_username)">{{ $t('cancel') }}</button>
          <button v-if="customDetail.status === 1" class="btn btn-primary btn-purchase px-4 py-1 mb-2 mb-sm-0" type="button" @click="setLocal">
            <router-link class="font-weight-normal" :to="'../' + customDetail.task_owner + '/' + customDetail.slug">{{ $t('purchase') }}</router-link>
          </button>
        </div>
      </div>
    </div>

    <!-- Custom task info dropdown BUYER/SELLER -->
    <b-collapse v-if="customDetail.status != null" v-model="visible" id="custom-task-details-description">
      <div class="holder d-inline-flex flex-column w-100 px-3 py-2">
        <div class="row">
          <div class="col-sm-4 col-12 text-left">
            <span class="font-weight-bold">{{ $t('include_4') }}:</span>&nbsp;<span>{{this.customDetail.price !== null ? '&dollar;' + this.customDetail.price : $t('tbc')}}</span>
          </div>
          <div class="col-sm-4 col-12 text-left">
            <span class="font-weight-bold">{{ $t('task_advice_title_delivery') }}:</span>&nbsp;<span>{{this.customDetail.delivery / 24}}</span>&nbsp;<span>{{$t('delivery_time_days')}}</span>
          </div>
          <div class="col-sm-4 col-12 text-left">
            <span class="font-weight-bold">{{ $t('task_advice_title_revisions') }}:</span>&nbsp;<span>{{this.customDetail.revisions}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left pt-0 pt-sm-1">
            <span class="font-weight-bold">{{ $t('task_advice_title_requirements') }}</span>:&nbsp;{{this.customDetail.info}}
          </div>
        </div>
      </div>
    </b-collapse>

    <!-- custom task AMEND modal -->
    <b-modal id="amendModal" v-if="messages.length !== 0" :title="messages[0].task_title" busy >
      <div class="p-sm-3">
        <div class="row">
          <div class="col-md-12 text-left">
            <p><span class="font-weight-bold">{{messages[0].author_username}}&nbsp;</span>{{ $t('custom_quote_amend_p1') }}<span class="font-weight-bold">{{messages[0].author_username}}&nbsp;</span>{{ $t('custom_quote_amend_p2') }}</p>
            <p>{{ $t('custom_quote_amend_p3') }}</p>
            <b-alert v-if="validationsErrorSwitch" show variant="danger">
              <ul v-if="validationErrors.length > 1">
                <li v-for="(message, index) in validationErrors" v-bind:key="index">
                  {{message}}
                </li>
              </ul>
              <p v-else>{{validationErrors[0]}}</p>
            </b-alert>
          </div>
          <div class="col-md-12">
              <label >{{ $t('task_advice_title_delivery') }}</label>
              <b-form-select v-model="deliveryDays" :value="deliveryDays" class="mb-2" @change="onChange('language')">
                  <option v-for="(day, index) in $t('daysOptions')" v-bind:key="index" :value="index" >{{day}}</option>
              </b-form-select>
              <label >{{ $t('task_advice_title_revisions') }}</label>
              <b-form-input v-model="revisions" type="number" step="1" :min="0" minvalue="0" :max=10 maxvalue="10" aria-valuemax=10 aria-maxlength="2" maxlength="2" class="mb-2" :class="revisions_error_border ? 'border-danger' : ''">
              </b-form-input>
              <label class="text-capitalize">{{ $t('task_package_price') }}</label>
              <b-input-group prepend="$">
                <b-form-input v-model="budget" type="number" step="00.01" :max=500.00 maxvalue="500.00" aria-valuemax=500.00 aria-maxlength="6" maxlength="6" :class="budget_error_border ? 'border-danger' : ''">
                </b-form-input>
              </b-input-group>
              <b-button v-if="!customSubmit" v-on:click="customQuoteEdit(customDetail.hash)" class="w-100 task-purchase btn mt-4 mb-0 d-md-block" variant="primary">
                {{ $t('send_custom_quote') }}</b-button>
              <b-button v-else class="w-100 task-purchase btn mt-4 mb-0 d-md-block" variant="primary">
                <b-spinner small label="small Spinner" />
              </b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <template v-if="this.jobDetails.hash !== null">
    <div class="job-details flex-col align-items-center flex-sm-row justify-content-sm-between py-2 px-3 py-1">
      <div class="cursor-pointer py-2" @click="jobVisible = !jobVisible">
        <i class="fa-solid mr-2" :class="jobVisible ? 'fa-chevron-up' : 'fa-chevron-down'" aria-hidden="true"/>
        <span>{{ $tc('job.message_system.show_hide_job_details', jobVisible) }}</span>
      </div>
      <div class="py-1 d-flex justify-content-center">
        <template v-if="this.username === messages[0].author_username">
          <button class="btn btn-primary" v-if="[0, 4].includes(this.jobDetails.status)" @click="handleJobOfferButton()">{{ $t('job.card.button.make_offer') }}</button>
          <button class="btn btn-danger" v-else-if="this.jobDetails.status === 1" @click="handleJobCancelButton()">{{ $t('job.card.button.cancel_offer') }}</button>
        </template>
        <template v-else-if="[1, 2].includes(this.jobDetails.status)">
          <button class="btn btn-primary mr-2" @click="handleJobPurchase()">
            <b-spinner small label="small Spinner" v-if="buttonJobPurchaseSpinner" /><span v-else>{{ $t('purchase') }}</span>
          </button>
          <button class="btn btn-danger" @click="handleJobCancelButton()">{{ $t('decline_button') }}</button>
        </template>
        <router-link v-if="this.jobDetails.page !== 'job_view' && messages[0].order_hash !== null" :to="{ name: 'receive_task', params: { hash: messages[0].order_hash } }" class="btn btn-dark ml-2" >{{ $t('view_order_button') }}</router-link>
        <router-link v-else-if="this.jobDetails.page !== 'job_view'" :to="{ name: 'jobs_view', params: { hash: this.jobDetails.hash } }" class="btn btn-dark ml-2" >{{ $t('job.card.button.view_job') }}</router-link>
      </div>
    </div>

    <b-collapse v-model="jobVisible" id="job-details-description">
      <div class="holder d-inline-flex flex-column w-100 px-3 py-2">
        <div class="row justify-content-between mr-2 ml-0">
          <div>
            <template v-if="this.jobDetails.status === 0">
              <span class="font-weight-bold">{{ $t('job.message_system.budget') }}</span>&nbsp;&dollar;{{ this.jobDetails.price_from }} - &dollar;{{ this.jobDetails.price_to }}
            </template>
            <template v-else>
              <span class="font-weight-bold">{{ $t('job.message_system.price') }}</span>&nbsp;<span>{{this.jobDetails.price !== null ? '&dollar;' + this.jobDetails.price : $t('tbc')}}</span>
            </template>
          </div>
          <div>
            <template v-if="this.jobDetails.status === 0">
              <span class="font-weight-bold">{{ $t('job.message_system.expired_date') }}</span>&nbsp;<span>{{ moment(this.jobDetails.date_expires).fromNow() }}</span>
            </template>
            <template v-else>
              <span class="font-weight-bold">{{ $t('job.message_system.delivery') }}</span>&nbsp;<span>{{ $tc('job.message_system.day', this.jobDetails.delivery / 24) }}</span>
            </template>
          </div>
          <div v-if="this.jobDetails.status !== 0">
            <span class="font-weight-bold">{{ $t('job.message_system.revisions') }}</span>&nbsp;<span>{{  this.jobDetails.revisions  }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left pt-0 pt-sm-1">
            <span class="font-weight-bold">{{ $t('job.message_system.requirement') }}</span>&nbsp;<span> <div v-html="getJobDescription" /></span>
          </div>
        </div>
      </div>
    </b-collapse>
    </template>

    <!-- translation error -->
    <div v-if="translateError !== null" class="alert alert-danger" role="alert">
      {{translateError}}
    </div>

    <!-- CHAT MESSAGES -->
    <div id="messageBox" class="message-chat-history w-100 px-3 d-flex flex-column pt-3" v-lazy-container="{ selector: 'img' }">

      <!-- READ CHAT MESSAGES -->
      <div v-for="(message, index) in getReadMessages" :key="index" >
        <SystemItem :message="message" v-if="message.author_username === 'ANYTASKSYSTEM'" />
        <ChatItem v-else :message="message" :current-user="username" :disputePositionData="disputePositionData" />
      </div>

      <!-- UNREAD CHAT MESSAGES -->
      <div class="borderLine w-100 mb-4 mt-2" id="readLine" v-if="unreadMessage.length > 0 ">
        <span class="lineThrough font-italic px-3 mt-1">{{$t('unread_message')}}</span>
      </div>

      <div v-for="(message, index) in unreadMessage" :key="'unread' + index">
        <ChatItem :message="message" :current-user="username" :disputePositionData="disputePositionData"/>
      </div>

      <div v-if="ErrorMessageSwitch">
        <p class="error"><span class="lineThrough">{{$t('message_error')}}</span></p>
        <div class="chat-item-content">
          <div class="chat-item-content-inner">
            <div class="msg">
              <span>{{ErrorMessage}}</span>
              <span>{{ErrorFile}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- ACTIONS -->
    <div class="type-message px-3 mb-3 mt-0" v-if="!adminDisableInput">
      <!-- completed task message -->
      <div class="alert alert-danger m-0" role="alert" v-if="lock == 1 && !admin">
        <small><i class="fa-solid fa-ban mr-1 d-block d-md-inline-flex" aria-hidden="true" />{{ $t('message_locked') }}</small>
      </div>
      <!-- dropzone -->
      <div :class="{ 'd-none': !loadDropzone }">
        <vue-dropzone ref="myVueDropzone" id="dropzone" class="p-4" :options="dropzoneOptions" :useCustomSlot=true
                      :awss3="awss3"
                      v-on:vdropzone-s3-upload-error="s3UploadError"
                      v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                      v-on:vdropzone-files-added="preFileUpload"
                      v-on:vdropzone-removed-file='dropzoneRemoved'>
          <div class="dropzone-custom-content text-muted">
            <i class="fa-solid fa-cloud-arrow-up" />
            <br />
            {{ $t("dropzone_placeholder")}}
          </div>
        </vue-dropzone>
      </div>

      <!-- Buttons -->
      <div class="d-flex" v-if="lock != 1">
        <div class="flex-fill position-relative">
          <div contenteditable="true" :id="id" class="textAreaStyle p-3 pr-4 text-left" v-on:focus="changeDefaultText()" v-on:keyup.ctrl.enter="sendMessage()" v-on:blur="changeDefaultText">{{$t('message_input_placeholder')}}</div>
          <div class="position-absolute upload-button">
            <button class="btn px-2" type="button" :disabled="fileUploading" v-if="!admin && !loadingDropzone" @click="showDropzone()">
              <i class="fa-solid fa-paperclip" aria-hidden="true" />
            </button>
            <button v-else-if="!admin" class="btn" type="button">
              <b-spinner small label="small Spinner" />
            </button>
          </div>
        </div>

        <!-- Send Message Button -->
        <div class="align-self-center pl-2">
          <button v-if="!messageSending " class="btn btn-primary w-100 h-100 py-2" type="button" :disabled="fileUploading" @click="sendMessage()"><i class="fa-solid fa-paper-plane" aria-hidden="true" /></button>
          <button v-else class="btn btn-primary w-100 h-100" type="button">
            <b-spinner small label="small Spinner" />
          </button>
        </div>
      </div>
      <div v-if="admin && preDefinedResponses.length > 0">
        <b-dropdown class="mb-3 mt-3 float-left dropdown-menu-right" text="Pre-defined responses" id="dispute">
          <b-dropdown-item v-for="option in preDefinedResponses" class=" m-0 p-0" :key="option.id" @click="setMessage(option.text)">{{ option.text }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <Report :type="$t('message').toLowerCase()" :refId="deviceKey + hash" :options="$t('message_report_reasons')" :hash="hash"/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import ApiService from '../../../apiService.js'
import Report from '../../Task/Report.vue'
import helper from '../../../helper'
import GoogleTranslate from '../../Global/GoogleTranslate'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ChatItem from '../../Message/ChatItem.vue'
import SystemItem from '../../Message/SystemItem.vue'
import config from '../../../config'

export default {
  name: 'messageInbox',
  props: {
    device: {
      type: String,
      required: false,
      default () {
        return 'desktop'
      }
    },
    messages: Array,
    displayInbox: Function,
    hash: String,
    title: String,
    unreadMessage: Array,
    lock: Boolean,
    online: Boolean,
    toLastActiveDate: String,
    fromLastActiveDate: String,
    withinLastSeconds: Number,
    activeMessage: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    id: {
      type: String,
      required: false,
      default () {
        return 'message'
      }
    },
    admin: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    adminDisableInput: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    orderView: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showHeader: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    customDetail: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    jobDetails: {
      type: Object,
      required: false,
      default () {
        return {
          hash: null
        }
      }
    },
    disputePositionData: {
      type: [Object, Boolean],
      required: false,
      default () {
        return false
      }
    },
    fromUsername: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    toUsername: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    adminUsernameCheck: {
      type: String,
      required: false,
      default () {
        return null
      }
    },
    preDefinedResponses: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    displayBackButton: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    }
  },
  components: {
    ChatItem,
    SystemItem,
    GoogleTranslate,
    Report,
    vueDropzone
  },
  data () {
    return {
      messageInput: '',
      loadingDropzone: false,
      ErrorMessage: '',
      ErrorFile: '',
      ErrorMessageSwitch: false,
      username: this.adminUsernameCheck === null ? localStorage.getItem('username') : this.adminUsernameCheck,
      language: localStorage.getItem('language'),
      messageReadLine: false,
      messageSending: false,
      files: [],
      deviceKey: this.device,
      originalWord: [],
      translateError: null,
      visible: screen.width >= 760,
      jobVisible: screen.width >= 760,
      selectedDelivery: null,
      additionalInfo: this.customDetail.info,
      maxCharacters: 200,
      awss3: {
        signingURL: ApiService.getTokenUrl('message'),
        headers: {},
        params: {
          'hash': this.hash
        },
        sendFileToServer: false
      },
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        maxFilesize: 500,
        maxFiles: 5,
        autoProcessQueue: true,
        parallelUploads: 2,
        timeout: 0,
        parallelChunkUploads: false,
        retryChunks: true,
        retryChunksLimit: 3,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      },
      loadDropzone: false,
      fileUploading: false,
      requestRefreshToken: true,
      deliveryDays: this.customDetail.delivery,
      revisions: this.customDetail.revisions,
      budget: this.customDetail.price,
      validationErrors: [],
      validationsErrorSwitch: false,
      upload_data: '',
      customSubmit: false,
      revisions_error_border: false,
      budget_error_border: false,
      buttonJobPurchaseSpinner: false
    }
  },
  watch: {
    newMessageThreads: function (newVal, oldVal) {
      this.checkNewMessage()
    }
  },
  computed: {
    ...mapGetters(['hasMessageThread', 'getUsername', 'getUserHash']),
    ...mapState({ newMessageThreads: state => state.messages.newMessageThreads }),
    getReadMessages () {
      return this.messages.filter(function (msg) {
        return msg.read > 0
      })
    },
    getJobDescription () {
      return this.$sanitize(this.jobDetails.description, {
        allowedTags: ['br', 'p', 'ul', 'li', 'ol']
      })
    }
  },
  mounted () {
    this.scrollToEnd()
    this.setWidth()
  },
  methods: {
    localTime (timezone) {
      return new Date().toLocaleString([], { year: '2-digit', month: '2-digit', day: '2-digit', timeZone: timezone, hour: '2-digit', minute: '2-digit', timeZoneName: 'short' })
    },
    checkNewMessage () {
      if (this.hasMessageThread(this.hash) && !this.isUserTyping()) {
        this.reloadMessages()
      }
    },
    scrollToEnd () {
      let container = this.$el.querySelector('#messageBox')
      container.scrollTop = container.scrollHeight + 100
    },
    changeDefaultText () {
      let text = document.getElementById(this.id).innerText
      if (text === this.$t('message_input_placeholder')) {
        document.getElementById(this.id).innerText = ''
      } else if (text === '') {
        document.getElementById(this.id).innerText = this.$t('message_input_placeholder')
      }
    },
    setMessage (text) {
      document.getElementById(this.id).innerText = text
    },
    sendMessage () {
      this.messageInput = document.getElementById(this.id).innerText.replace(new RegExp(/(\n){3,}/, 'gim'), '\n\n')

      let send = false
      let message = ''
      this.ErrorMessageSwitch = false
      this.ErrorMessage = ''
      this.ErrorFile = ''
      if (this.messageInput.length > 0 && this.messageInput !== this.$t('message_input_placeholder')) {
        message = this.messageInput
        send = true
      }
      if (this.files.length !== 0) {
        send = true
      }
      if (send) {
        this.messageSending = true
        let vm = this
        ApiService.postMessage(this.hash, message, this.files).then(response => {
          this.inbox = response.data.data
          this.messageSending = false
          vm.reloadMessages()
          this.messageInput = ''
          document.getElementById(this.id).innerText = ''
        }).catch((error) => {
          this.ErrorMessageSwitch = true
          this.messageSending = false
          let errors = error.response.data.errors.children
          if (errors.message.errors.length > 0) {
            this.ErrorMessage = errors.message.errors[0]
          }
          if (errors.file.errors.length > 0) {
            this.ErrorFile = errors.file.errors[0]
          }
        })
      }
    },
    reloadMessages () {
      if (this.id === 'seller' || this.id === 'buyer') {
        this.$vnode.context.getDisputeMessages(this.hash, this.id)
      } else if (this.id === 'dispute') {
        this.$vnode.context.getMessageDispute()
      } else {
        this.$vnode.context.getMessage(this.hash, this.activeMessage, this.lock, false, this.fromUsername, this.toUsername, false)
      }
      this.originalWord = []
    },
    getInputMessage () {
      let textElement = document.getElementById(this.id)
      if (textElement !== null && textElement.textContent !== this.$t('message_input_placeholder')) {
        return textElement.textContent
      }
      return ''
    },
    isUserTyping () {
      if (this.getInputMessage().length > 0) {
        return true
      }
      return this.files.length !== 0
    },
    formatProfilePhoto (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    report () {
      this.$bvModal.show(this.deviceKey + this.hash)
    },
    translateWord (event) {
      this.$refs.googleTranslate.showSpinner(true)
      this.translateError = null

      const inbounds = document.getElementsByClassName('message-inbound')
      if (this.originalWord.length === 0) {
        for (let i = 0; i < inbounds.length; i++) {
          this.originalWord.push(inbounds[i].innerText)
        }
      }

      if (this.originalWord.length === 0) {
        this.$refs.googleTranslate.showSpinner(false)
        return
      }

      const locale = event.target.value

      if (locale === '') {
        for (let i = 0; i < inbounds.length; i++) {
          inbounds[i].innerText = this.originalWord[i]
        }
        this.$refs.googleTranslate.showSpinner(false)
      } else {
        ApiService.getTranslation(this.originalWord, locale).then((resp) => {
          for (let i = 0; i < inbounds.length; i++) {
            inbounds[i].innerText = resp.data.data[i]
          }
          this.$refs.googleTranslate.showSpinner(false)
        }).catch(error => {
          this.$refs.googleTranslate.showSpinner(false)
          this.translateError = error.response.data.error_message
        })
      }
    },
    setLocal () {
      localStorage.setItem('signUpType', 'purchaseTask')
    },
    ...mapActions([
      'fetchNewAccessToken'
    ]),
    showDropzone () {
      if (!this.loadDropzone) {
        if (this.requestRefreshToken) {
          this.loadingDropzone = true
          this.fetchNewAccessToken().then(() => {
            this.awss3.headers = {
              'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
            }
            this.loadDropzone = true
            this.loadingDropzone = false
            this.requestRefreshToken = false
          })
        } else {
          this.loadDropzone = true
        }
      } else {
        this.loadDropzone = false
      }
    },
    s3UploadError (errorMessage) {
      this.fileUploading = false
      this.errorMessageSwitch = true
      this.ErrorFile = errorMessage
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.fileUploading = false
      this.files.push(s3ObjectLocation)
    },
    preFileUpload () {
      this.fileUploading = true
    },
    dropzoneRemoved (file) {
      let array = this.files
      for (let i = 0; i < array.length; i++) {
        let imageName = array[i].split('%2F').pop()
        if (imageName === file.upload.filename) {
          array.splice(i, 1)
          break
        }
      }
    },
    acceptDeclineQuote (hash, value, loggedIn, author) {
      let vm = this
      this.$swal({
        title: value ? this.$t('custom_quote_accept_title') : this.$t('custom_quote_decline_title'),
        text: value ? this.$t('custom_quote_accept_desc') : this.$t('custom_quote_decline_desc'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('no'),
        confirmButtonText: this.$t('yes'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        customClass: {
          input: loggedIn === author ? 'buyer-decline' : 'seller-decline'
        },
        input: !value ? 'textarea' : '',
        inputPlaceholder: this.$t('custom_decline_placeholder'),
        inputValidator: (value) => {
          if (!value && loggedIn !== author) {
            return this.$t('custom_validation_decline')
          }
        },
        preConfirm: (result) => {
          if (result !== false) {
            let data
            if (value) {
              data = { 'status': 1 }
            } else {
              data = loggedIn === author ? { 'status': 2 } : { 'status': 0, 'message': result }
            }
            return ApiService.approveDeclineCustomQuote(hash, data).then(() => {
              if (data.status === 0 && config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  'event': 'customQuoteDecline',
                  'sellerID': this.messages[0].seller_hash,
                  'taskID': this.messages[0].task_hash
                })
              }

              if (data.status === 1 && config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  'event': 'customQuoteApprove',
                  'sellerID': this.messages[0].seller_hash,
                  'taskID': this.messages[0].task_hash
                })
              }
            }).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
              return false
            })
          }
        }
      }).then((result) => {
        if (!result.dismiss) {
          this.$swal({
            title: this.$t('success'),
            text: value ? this.$t('custom_accept_success') : this.$t('custom_decline_success'),
            type: 'success',
            confirmButtonText: this.$t('close_button')
          }).then(() => {
            vm.$parent.changeCustomStatus(vm.hash, value ? 1 : 2, vm.activeMessage, vm.deliveryDays, vm.revisions, vm.budget)
            vm.reloadMessages()
          })
        }
      })
    },
    customQuoteEdit (hash) {
      this.customSubmit = true
      if (this.validateForm()) {
        ApiService.customQuoteEdit(hash, this.upload_data).then(() => {
          if (config.google_tag_manager !== '' && config.tracking_enabled === 'true') {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'customQuoteReview',
              'sellerID': this.messages[0].seller_hash,
              'taskID': this.messages[0].task_hash
            })
          }

          this.customDetail.price = this.upload_data.price
          this.customDetail.delivery = this.upload_data.delivery
          this.customDetail.revisions = this.upload_data.revisions
          this.customSubmit = false
          this.$bvModal.hide('amendModal')
          this.acceptDeclineQuote(hash, true, null, null)
        }).catch((error) => {
          this.customSubmit = false
          this.validationsErrorSwitch = true
          this.validationErrors.push(error.response.data.error_message)
        })
      }
    },
    validateForm () {
      this.validationErrors = []
      this.validationsErrorSwitch = false
      this.revisions_error_border = false
      this.budget_error_border = false
      this.budget = parseFloat(this.budget).toFixed(2)
      this.revisions = parseFloat(this.revisions).toFixed(0)

      // Set the form data
      let formData = {
        'delivery': this.deliveryDays,
        'price': this.budget,
        'revisions': this.revisions
      }

      if (isNaN(this.budget) || this.budget > 500 || this.budget < 1) {
        this.validationErrors.push(this.$t('your_max_price_500'))
        this.budget_error_border = true
      }

      if (isNaN(this.revisions)) {
        this.validationErrors.push(this.$t('please_enter_revisions'))
        this.revisions_error_border = true
      } else if (this.revisions < 0 || this.revisions > 10) {
        this.validationErrors.push(this.$t('custom_quote_revisions_validation'))
        this.revisions_error_border = true
      }

      if (this.validationErrors.length > 0) {
        this.validationsErrorSwitch = true
        this.customSubmit = false
        return false
      }

      this.upload_data = formData
      return true
    },
    onChange (event) {
      if (event === 'language') {
        this.language_validation = false
      }
    },
    isUserOnline (date, withinLastSeconds) {
      return helper.isUserOnline(date, withinLastSeconds)
    },
    setWidth () {
      let messageBox = document.getElementById('message')
      if (messageBox) {
        messageBox.style.maxWidth = messageBox.offsetWidth + 'px'
      }
    },
    displayLastSeen (date) {
      if (helper.displayStaticLastSeen(date)) {
        return this.$t('last_seen_static')
      }
      return this.$moment(date).from()
    },
    handleJobCancelButton () {
      this.$emit('job-button-cancel-offer', this.jobDetails)
    },
    handleJobOfferButton () {
      this.$emit('job-button-open-offer')
    },
    handleJobPurchase: function () {
      this.buttonJobPurchaseSpinner = true
      ApiService.purchaseJobOffer(this.jobDetails.hash, this.jobDetails.offer_hash).then((resp) => {
        this.$tracker.buyerPurchaseJob('clickAcceptJobOffer', {
          'ecommerce': {
            'products': [{
              'item_name': resp.data.data.gig.title,
              'price_from': this.jobDetails.price_from,
              'price_to': this.jobDetails.price_to,
              'item_id': this.jobDetails.hash
            }]
          },
          'offer': {
            'price': this.jobDetails.price,
            'revisions': this.jobDetails.revisions,
            'delivery': this.jobDetails.delivery
          }
        })
        this.$router.push('/checkout/' + resp.data.data.hash + '/pay')
      }).catch(() => {
        this.buttonJobPurchaseSpinner = false
      })
    }
  }
}
</script>

<style lang="scss">
  #amendModal.modal.show .modal-dialog {
    max-width: 530px!important;
  }
  #message-container .dz-preview {
    pointer-events: all !important;
  }
  #amendModal {
    .modal-title {
      font-weight: bold;
    }
    .modal-footer {
      display: none;
    }
    p{
       font-size: 14px;
    }
  }
  .swal2-actions {
    flex-direction: row-reverse;
  }
  .swal2-textarea {
    width: 100%;
    &.buyer-decline {
      display: none !important;
    }
  }
 #dispute .dropdown-menu {
    width: 600px;
    overflow: overlay;
  }
</style>
<style lang="scss" scoped>

  li, ul{
    list-style-type: unset;
  }

  .dropzone {
    min-height: auto;
    margin-bottom: 1rem;
  }

  .prewrap {
    white-space: pre-wrap;
  }

  .type-form {
    .btn {
      max-height: 50px;
    }
  }

  @media (max-width: 767.98px) {
    .task-header .task-info
    & h3 {
      font-size: 1.20rem;
    }
  }

  @media (min-width: 996px) {
    .borderLine {
      border-bottom: 1px solid #ddd !important;
      width: 100%;
      text-align: center;
      line-height: 0.1em;
      margin: 10px 0 20px;
    }
  }

  .task-actions {
    border-top: 1px solid #ddd;
  }

  .task-header {
    border-bottom: 1px solid #ddd;
    box-shadow: 0 7px 12px -12px rgba(34,34,34,0.35);
    .back-btn i {
      font-size: 3rem;
    }
    .task-info {
      h3 {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .task-seen-info {
        color: $light-grey;
      }
      .task-seen-info, .task-sub-info {
        font-size: .75rem;
      }
      .task-sub-info {
        font-size: .75rem;
        a {
          color: $brand-denim;
        }
        .username {
          display: inline-block;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          max-height: 18px;
          display: inline-block;
          display: -webkit-box;
          line-height: 1.65;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .task-actions {
        border-top: 1px solid #ddd;
      }
      button {
        border: 0;
        background: transparent;
        color: $brand-denim;
      }
    }
  }

  .custom-task-details, .job-details {
    background: $task-light-grey;
    font-size: 12px;
    border-bottom: 1px solid #d4d4d4;
    box-shadow: 0 7px 12px -12px rgba(34,34,34,0.35);
    .btn {
      font-size: 12px;
      color: white;
    }
    .btn-purchase {
      animation: pulse 2s infinite;
      a:hover {
        color: white;
      }
    }
    .button-holders {
      width: 100%;
      @include sm-up {
        width: auto;
      }
    }
  }
  #custom-task-details-description, #job-details-description {
    background-color: #f5f5f5;
    border-radius: 0;
    border: 0;
    font-size: 12px;
    overflow: auto;
    overflow-y: scroll;
    max-height: 150px;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba($brand-sky, 0.7);
    }
    60% {
      box-shadow: 0 0 0 8px rgba($brand-sky, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba($brand-sky, 0);
    }
  }

  .flag-button {
    width: auto;
    max-height: 35px;
  }

  .new-message-fix {
    max-height: 586px;
  }

  .order-view-message-header #googleTranslate {
    padding-left: 3rem !important;
    &::after {
      background: none;
    }
    &::before {
      content: '';
      position: absolute;
      width: 30px;
      height: 30px;
      background: url('~@/assets/google-translate-logo.png');
      left: 7px;
      top: 3px;
      background-position: center;
      background-size: contain;
    }
  }

  @include md-up {
    .task-header {
      .task-actions {
        border-top: 0;
      }
      .task-info {
        h3 {
          height: 34px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .borderLine {
    border-bottom: 1px solid $brand-denim !important;
    text-align: center;
    line-height: 0.1em;
    span {
      color: $brand-sky;
      background: white;
      font-size: .75rem;
      line-height: 0rem;
    }
  }

  p .lineThrough {
    background: $background-grey;
    padding: 0 10px;
  }

  .error {
    border-bottom: 1px solid #DC3545 !important;
    width: 100%;
    text-align: center;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  a {
    text-decoration: unset !important;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
  }

  #files {
    visibility: hidden;
  }

  .textAreaStyle {
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
    background: white;
    border: 1px solid $task-light-grey;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.05);
    font-size: 12px;
    color: #7c7c7d;
    border-radius: 5px;
    max-height: 100px;
    padding-right: 40px !important;
  }

  .text-black {
    color: #000000 !important;
  }

  .upload-button {
    right: 12px;
    top: 6px;
  }
  .user-online.badge:before {
    height: 100%;
  }

  .border-danger{
    border-width: 2px ;
    border-style: solid;
  }

</style>
